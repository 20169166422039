import * as React from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import { Row } from 'react-bootstrap';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import * as styles from './content-page-template.module.scss';

const ContentPageTemplate = ({
  data: {
    allMdx: { edges },
  },
}) => {
  const postContent = edges[0].node;
  return (
    <Layout>
      <Seo
        title={postContent.frontmatter.title}
        description={postContent.frontmatter.metadescription}
      />
      <Row>
        <div className={`py-5 mx-auto ${styles.content}`}>
          <MDXRenderer>{postContent.body}</MDXRenderer>
        </div>
      </Row>
    </Layout>
  );
};

export const query = graphql`
  query contentData($nodeId: String!) {
    allMdx(filter: { id: { eq: $nodeId } }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          slug
          body
          frontmatter {
            metadescription
            metakeywords
            title
          }
        }
      }
    }
  }
`;

export default ContentPageTemplate;
